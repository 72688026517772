import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ComboPlanForm = ({ showModal, onClose, onPlanAdded, selectedCardCombo }) => {
  const [planos, setPlanos] = useState([{ nome: '', descricao: '', preco: '', velocidadeInternet: '' }]);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Limpar os campos do formulário
  const resetForm = () => {
    setPlanos([{ nome: '', descricao: '', preco: '', velocidadeInternet: '' }]);
  };

  // Adicionar mais planos dinamicamente
  const handleAddPlano = () => {
    setPlanos([...planos, { nome: '', descricao: '', preco: '', velocidadeInternet: '' }]);
  };

  // Remover um plano
  const handleRemovePlano = (index) => {
    const updatedPlanos = planos.filter((_, i) => i !== index);
    setPlanos(updatedPlanos);
  };

  // Atualizar valores dos campos dos planos
  const handlePlanoChange = (index, field, value) => {
    const updatedPlanos = planos.map((plano, i) =>
      i === index ? { ...plano, [field]: value } : plano
    );
    setPlanos(updatedPlanos);
  };

  // Submeter o formulário e salvar os planos
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/card_combos/${selectedCardCombo}/planos`, { planos });

      if (response.status === 201) {
        toast.success('Planos cadastrados com sucesso!');
        onPlanAdded(response.data.cardCombo); // Atualizar o estado no componente pai
        resetForm(); // Limpar o formulário após o cadastro
        onClose(); // Fechar o modal após o cadastro
      } else {
        toast.error('Erro ao cadastrar os planos.');
      }
    } catch (error) {
      console.error('Erro ao cadastrar os planos:', error);
      toast.error('Erro ao cadastrar os planos.');
    }
  };

  // Resetar o formulário sempre que o modal for fechado
  useEffect(() => {
    if (!showModal) {
      resetForm(); // Limpar o formulário quando o modal for fechado
    }
  }, [showModal]);

  return (
    <>    
      <Modal show={showModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Planos ao CardCombo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {planos.map((plano, index) => (
              <div key={index} className="mb-4">
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label className="fs-sm-1">Nome do Plano</Form.Label>
                      <Form.Control
                        type="text"
                        value={plano.nome}
                        onChange={(e) => handlePlanoChange(index, 'nome', e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label className="fs-sm-1">Velocidade da Internet</Form.Label>
                      <Form.Control
                        type="text"
                        value={plano.velocidadeInternet}
                        onChange={(e) => handlePlanoChange(index, 'velocidadeInternet', e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Label className="fs-sm-1">Descrição</Form.Label>
                  <Form.Control
                    type="text"
                    value={plano.descricao}
                    onChange={(e) => handlePlanoChange(index, 'descricao', e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group>                 
                  <Form.Label className="fs-sm-1">Preço</Form.Label>
                  <Form.Control
                    type="text"
                    value={plano.preco}
                    onChange={(e) => handlePlanoChange(index, 'preco', e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="danger" onClick={() => handleRemovePlano(index)} className="mt-2">
                  Remover Plano
                </Button>
              </div>
            ))}

            <Button variant="outline-secondary" onClick={handleAddPlano}>
              Adicionar Mais Planos
            </Button>

            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="primary" type="submit">
                Cadastrar Planos
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ComboPlanForm;
