import React, { useState, useContext } from "react";
import axios from "axios";
import { Form, FormControl, FormGroup, Card } from 'react-bootstrap';
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logoGiga.png";
import CMS from "../../assets/images/cms-icon-9.png";
import Separator from "../../assets/images/separator.png";
import jwtDecode from "jwt-decode"; // Importe o jwt-decode

import "./LoginForm.css";

const LoginForm = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  // Quando você definir o auth no login:
const handleLogin = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      email,
      password,
    });

    const token = response.data.accessToken;
    const decoded = jwtDecode(token); // Decodifica o token
    setAuth({ token, userId: decoded.userId, userName: decoded.name }); // Armazena as informações no contexto
    console.log(decoded.userName);
    navigate("/home");
  } catch (error) {
    if (error.response && error.response.status === 401) {
      setErrorMsg("Credenciais inválidas, por favor tente novamente.");
    } else {
      setErrorMsg("Credenciais inválidas, por favor tente novamente.");
    }
    console.error(error.response.data.msg);
  }
};
  
  return (
    <div className="login-bg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-3 mx-auto text-center">
            <Card>
              <Card.Body>
                <div className='login'>
  
                  <div className='cms-logo'>
                    <img src={CMS} alt="" />
                    <p>CMS (Content Management System)</p>
                  </div>
                  <div className="separator">
                    <img src={Separator} alt="separator" />
                  </div>
  
                  {/* Exibe a mensagem de erro se houver */}
                  {errorMsg && <p className="text-danger">{errorMsg}</p>}
  
                  <Form>
                    <FormGroup className='py-2'>
                      <FormControl
                        type="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='py-2'>
                      <FormControl
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                    <div className="botao">
                      <div
                        className="btn-hover color-7"
                        onClick={handleLogin}
                      >
                        Conectar
                      </div>
                    </div>
                  </Form>
                </div>
                <br />
                <div className="separator">
                  <img src={Separator} alt="separator" />
                </div>
                <div className='logo'>
                  <img src={Logo} alt="" />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
  
  
};

export default LoginForm;