import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ComboPlanForm from '../ComboPlanForm/ComboPlanForm';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ComboPlansDisplay = () => {
  const [comboPlans, setComboPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCardCombo, setSelectedCardCombo] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Função para buscar todos os combos
  const fetchComboPlans = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card_combos`);
      setComboPlans(response.data);
    } catch (error) {
      console.error('Erro ao buscar Combo Plans:', error);
      toast.error('Erro ao buscar Combo Plans.');
    }
  };

  useEffect(() => {
    fetchComboPlans(); // Buscando combos ao carregar o componente
  }, []);

  // Função para adicionar planos e atualizar o estado
  const handlePlanAdded = (updatedCombo) => {
    const updatedComboPlans = comboPlans.map((combo) =>
      combo._id === updatedCombo._id ? updatedCombo : combo
    );
    setComboPlans(updatedComboPlans);
    setShowModal(false); // Fechar o modal após adicionar planos
  };

  return (
    <div className='container'>
      <h4>Planos de Combo</h4>
      {comboPlans.map((combo) => (
        <Card key={combo._id} className="mb-3">
          <Card.Header>{combo.sub}</Card.Header>
          <Card.Body>
            <Card.Title>{combo.title}</Card.Title>
            <Card.Text>{combo.text}</Card.Text>
            <ListGroup>
              {combo.planos.map((plano, index) => (
                <ListGroup.Item key={index}>
                  <strong>Nome:</strong> {plano.nome} | <strong>Velocidade:</strong> {plano.velocidadeInternet} | <strong>Preço:</strong> {plano.preco}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button variant="primary" onClick={() => {
              setSelectedCardCombo(combo._id);
              setShowModal(true);
            }}>
              Adicionar Planos
            </Button>
          </Card.Body>
        </Card>
      ))}

      {/* Exibindo o modal para adicionar planos */}
      {selectedCardCombo && (
        <ComboPlanForm
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onPlanAdded={handlePlanAdded}
          selectedCardCombo={selectedCardCombo}
        />
      )}
    </div>
  );
};

export default ComboPlansDisplay;
