import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import loadingGif from '../../assets/gifs/giphy.webp'; // Certifique-se de ajustar o caminho para o GIF de carregamento
import TitleAnim from '../TitleAnim';
import Logo from '../../assets/images/logoGiga.png';


const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh; /* Ocupa a altura total da janela */
  background-color: #EFEFEF;  /* Cor de fundo opcional */
  color: #AB21B5;  
  font-weight: bold;
  fonte-size: 16px;
`;

const LoadSpinner = () => {
  return (
    <SpinnerContainer>
      <div className='title-gif'>
        <h1 className='title-anim'><TitleAnim texts={['Sistema de Gerenciamento de Conteúdo!']} /></h1>
      </div>
      <img src={loadingGif} alt="Carregando..." />
    </SpinnerContainer>
  );
};

export default LoadSpinner;
