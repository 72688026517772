import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const CardProduct = ({ categories, setCategories }) => {
    // Estado para armazenar a lista de produtos
    const [products, setProducts] = useState([]);
    // Estado para controlar a exibição do modal
    const [showModal, setShowModal] = useState(false);
    // Estado para indicar se está em modo de edição
    const [isEditing, setIsEditing] = useState(false);
    // Estado para armazenar o produto em edição
    const [editingProduct, setEditingProduct] = useState(null);

    // Estados para os campos do formulário
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [foto, setFoto] = useState(null);
    const [price, setPrice] = useState("");
    const [previewImage, setPreviewImage] = useState(null);

    // Estados para categorias
    // const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    // Efeito para buscar categorias ao carregar o componente
    useEffect(() => {
        fetchCategories();
    }, []);

    // Função para buscar categorias na API
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Erro ao buscar categorias:', error);
        }
    };

    // Componente para renderizar texto formatado
    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    // Efeito para buscar produtos ao carregar o componente
    useEffect(() => {
        fetchProducts();
    }, []);

    // Função para buscar produtos na API
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
            setProducts(response.data);
        } catch (error) {
            console.error("Erro ao buscar os produtos:", error);
        }
    };

    // Função para abrir o modal
    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingProduct(null);
        setShowModal(true);
    };

    // Função para fechar o modal e limpar os campos do formulário
    const handleCloseModal = () => {
        setShowModal(false);
        setName("");
        setDescription("");
        setSelectedCategory("");
        setFoto(null);
        setPrice("");
        setPreviewImage(null);
    };

    // Função para editar um produto
    const handleEditProduct = (product) => {
        setIsEditing(true);
        setEditingProduct(product);

        const categoryValue = typeof product.category === 'object' ? product.category._id : product.category;

        setName(product.name);
        setDescription(product.description);
        //setSelectedCategory(product.category);
        setSelectedCategory(categoryValue);
        setFoto(product.foto);
        setPrice(product.price);
        setPreviewImage(`${process.env.REACT_APP_API_URL}/assets/products/${product.foto}`);
        setShowModal(true);
    };

    // Função para fechar o modal de exclusão
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    // Função para deletar um produto
    const handleDeleteProduct = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/products/${deleteModalProductId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchProducts(); // Atualizar a lista de produtos

            toast.success('Produto deletado com sucesso!', { position: 'top-right', autoClose: 2000 });
        } catch (error) {
            console.error("Erro ao deletar o produto:", error);
        }
    };

    // Estados e funções relacionadas ao modal de exclusão
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalProductId, setDeleteModalProductId] = useState(null);

    // Função para submeter o formulário (cadastrar/editar produto)
    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("Selected Category:", selectedCategory);

        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("foto", foto);
        formData.append("category", selectedCategory);
        formData.append("price", price);

        try {
            if (isEditing && editingProduct) {
                await axios.put(`${process.env.REACT_APP_API_URL}/products/${editingProduct._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/products`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 });
            }

            fetchProducts();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o produto:", error);
        }
    };

    // Função para lidar com a mudança da foto no formulário
    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="title">
                    <Button onClick={handleOpenModal}>Cadastrar Produtos</Button>
                </div>
                <div className="table-container">
                    <table className="tabela">
                        <thead>
                            <tr>
                                <th className="col11">Imagem</th>
                                <th className="col22">Nome</th>
                                <th className="col33">Descrição</th>
                                <th className="col44">Preço</th>
                                <th className="col55">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product._id}>
                                    <td>
                                        <div className="imagem">
                                            <img
                                                src={`http://localhost:4001/assets/products/${product.foto}`}
                                                alt="product"
                                            />
                                        </div>
                                    </td>
                                    <td>{product.name}</td>
                                    <td>
                                        <TextRender htmlContent={product.description} />
                                    </td>
                                    <td>{product.price}</td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" onClick={() => handleEditProduct(product)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    onClick={() => {
                                                        setDeleteModalProductId(product._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                        </div>
                                        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirmar Exclusão</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Tem certeza que deseja excluir este produto? {deleteModalProductId}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="danger" onClick={handleDeleteProduct}>
                                                    Excluir
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Produto" : "Cadastrar Produto"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group controlId="category">
                                <Form.Label>Categoria</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="" disabled>Selecione uma categoria</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <FormGroup>
                                <Form.Label>Nome</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Preço</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Descrição</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={description} onChange={setDescription} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CardProduct;
