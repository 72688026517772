import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardCategory from '../../components/CardCategory';
import CardProduct from '../../components/CardProduct';

import './Products.css';

const Products = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  // Função para buscar categorias
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar as categorias:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="card-phone">
          <h3><i class="fa fa-fw fa-cart-arrow-down" aria-hidden="true" /> Página Produtos</h3>
          <span>Atenção para as dimensões das fotos para não distorcer o layout do site! Recomendação:  (1780x665 pixels)</span>

        </div>
        <div className="col-sm-12">
          <br />
          <CardCategory categories={categories} setCategories={setCategories} />
          <CardProduct categories={categories} />
        </div>
      </div>

    </>
  )
}
export default Products;