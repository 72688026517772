import React, { useEffect, useState } from "react";
import TitleAnim from "../../components/TitleAnim";
import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeInCard from "../../components/FadeInCard";

import './styles.css'; // Importe o estilo CSS para a animação
import CardVideoExplicativo from "../../components/HomeComponents/CardVideoExplicativo";

const Videos = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <>
            <div className="row">
                <div className="card-phone">
                    <h3><i class="fa fa-fw fa-video-camera" aria-hidden="true" /> Página Vídeos Explicativos</h3>
                    <span>Atenção para as dimensões das fotos para não distorcer o layout do site! Recomendação:  (1780x665 pixels)</span>
                </div>
                <div className="col-sm-12">
                    <br />
                    {/* <h3><TitleAnim texts={['Vídeos Explicativos']} /></h3> */}
                    <CardVideoExplicativo />
                </div>
            </div>

        </>
    );
}
export default Videos;