import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem("authToken");
    const name = localStorage.getItem("userName"); // Recupera o nome do localStorage
    const userId = localStorage.getItem("userId"); // Recupera o ID do localStorage
    return token ? { token, name, userId } : null; // Restaura o estado inicial com os dados do localStorage
  });

  useEffect(() => {
    if (auth?.token) {
      localStorage.setItem("authToken", auth.token);
      localStorage.setItem("userName", auth.name); // Armazena o nome do usuário
      localStorage.setItem("userId", auth.userId); // Armazena o ID do usuário
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userName");
      localStorage.removeItem("userId");
    }
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
