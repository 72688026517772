import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import FotoForm from "./FotoForm/FotoForm";

import { toast } from 'react-toastify';
import './styles.css'

const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const PostagemForm = () => {
    const [postagens, setPostagens] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingPostagem, setEditingPostagem] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [postagemFotos, setPostagemFotos] = useState({});

    useEffect(() => {
        fetchPostagens();
    }, []);

    const fetchPostagens = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/postagens`);
            setPostagens(response.data);

            const fotos = {};
            await Promise.all(response.data.map(async (postagem) => {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/fotos/${postagem._id}`);
                fotos[postagem._id] = res.data.map(foto => ({
                    ...foto,
                    url: `${process.env.REACT_APP_API_IMG}/assets/fotos/${foto.foto}`
                }));
            }));
            setPostagemFotos(fotos);
        } catch (error) {
            console.error("Erro ao buscar as postagens e fotos:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingPostagem(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
    };

    const handleEditPostagem = (postagem) => {
        setIsEditing(true);
        setEditingPostagem(postagem);
        setTitle(postagem.title);
        setSub(postagem.sub);
        setText(postagem.text);
        setShowModal(true);
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalPostagemId, setDeleteModalPostagemId] = useState(null);

    const [showDeleteFotoModal, setShowDeleteFotoModal] = useState(false);
    const [deleteModalFotoId, setDeleteModalFotoId] = useState(null);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleCloseDeleteFotoModal = () => {
        setShowDeleteFotoModal(false);
    };

    const hasFotosAssociated = async (postId) => {
        try {
            // Consultar todas as fotos para ver se elas têm o postagemId
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/fotos/${postId}`, {
                params: { postId },
            });

            console.log(postId);
            
            // Se a lista de fotos não está vazia, há associações
            return response.data.length > 0; 
        } catch (error) {
            console.error("Erro ao verificar fotos associadas:", error);
            return true; // Assumir que há associações em caso de erro
        }
    };


    const handleDeleteFoto = async (postId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/fotos/${postId}`);
            handleCloseDeleteFotoModal(); // Fechar o modal após a exclusão
            fetchPostagens(); // Atualizar a lista de postagens
            toast.success('Foto deletada com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar foto:", error);
            toast.error('Erro ao deletar foto', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        }
    };


    // const handleDeletePostagem = async (postagemId) => {
    //     try {
    //         await axios.delete(`${process.env.REACT_APP_API_URL}/postagens/${postagemId}`);
    //         handleCloseDeleteModal(); // Fechar o modal após a exclusão
    //         fetchPostagens(); // Atualizar a lista de fotos
    //         toast.success('Postagem deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
    //     } catch (error) {
    //         console.error("Erro ao deletar o postagem:", error);
    //         toast.error('Erro ao deletar o postagem', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
    //     }
    // };

    const handleDeletePostagem = async (postagemId) => {
        try {
            const hasFotos = await hasFotosAssociated(postagemId); // Verificar se há associações
            
            if (hasFotos) {
                // Não permitir exclusão se houver fotos associadas
                toast.error('Não é possível deletar a postagem enquanto houver fotos associadas.', { position: 'top-right', autoClose: 2000 });
                return;
            }
    
            // Se não houver associações, prosseguir com a exclusão da postagem
            await axios.delete(`${process.env.REACT_APP_API_URL}/postagens/${postagemId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchPostagens(); // Atualizar a lista de postagens
            toast.success('Postagem deletada com sucesso!', { position: 'top-right', autoClose: 2000 });
        } catch (error) {
            console.error("Erro ao deletar a postagem:", error);
            toast.error('Erro ao deletar a postagem', { position: 'top-right', autoClose: 2000 });
        }
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        const postData = { title, sub, text };

        try {
            if (isEditing && editingPostagem) {
                await axios.put(`${process.env.REACT_APP_API_URL}/postagens/${editingPostagem._id}`, postData);
                toast.success('Postagem editada com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/postagens`, postData);
                toast.success('Postagem cadastrada com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }
            fetchPostagens();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar a postagem:", error);
        }
    };

    // Adicione esta função no PostagemForm
    const updatePhotosAfterAddition = async (postId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/fotos/${postId}`);
            setPostagemFotos(prevFotos => ({
                ...prevFotos,
                [postId]: response.data.map(foto => ({
                    _id: foto._id,
                    url: `${process.env.REACT_APP_API_IMG}/assets/fotos/${foto.foto}`
                }))
            }));
        } catch (error) {
            console.error("Erro ao atualizar fotos:", error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Card>
                    <Card.Body>
                        <div className="title">
                            <Button onClick={() => setShowModal(true)}>Cadastrar Planos</Button>
                        </div>
                        <div className="table-container">
                            <table className="tabela">
                                <thead>
                                    <tr>
                                        <th className="col-titulo">Título</th>
                                        <th className="col-subtitulo">Subtítulo</th>
                                        <th className="col-texto">Texto</th>
                                        <th className="col-acoes">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postagens.map((postagem) => (
                                        <tr key={postagem._id}>
                                            <td className="col-titulo">{postagem.title}</td>
                                            <td className="col-subtitulo">{postagem.sub}</td>
                                            <td className="col-texto"><TextRender htmlContent={postagem.text} /></td>
                                            <td className="col-acoes">
                                                <div className="botoes">
                                                    <div className="botao">
                                                        <Button variant="outline-success" onClick={() => handleEditPostagem(postagem)}>
                                                            <i class="fa fa-edit" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div className="botao">
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() => {
                                                                setDeleteModalPostagemId(postagem._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                        </Button>

                                                        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                Tem certeza que deseja excluir este postagem? {deleteModalPostagemId}
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                                    Cancelar
                                                                </Button>
                                                                <Button variant="danger" onClick={() => { handleDeletePostagem(deleteModalPostagemId); setShowDeleteModal(false); }}>
                                                                    Excluir
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {postagens.map((postagem) => (
                                <>
                                    <div className="add-foto">
                                        <FotoForm postId={postagem._id} onPhotoAdded={() => updatePhotosAfterAddition(postagem._id)} />
                                    </div>
                                    <div className="postagem">
                                        {postagemFotos[postagem._id] && postagemFotos[postagem._id].map((foto) => (
                                            <>
                                                <div className="foto-postagem">
                                                    <img key={foto._id} src={foto.url} alt="Postagem" style={{ width: "100px", height: "100px" }} />

                                                    <Button
                                                        variant="outline-danger"
                                                        onClick={() => {
                                                            setDeleteModalFotoId(foto._id);
                                                            setShowDeleteFotoModal(true);
                                                        }}
                                                    >
                                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </Button>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </>
                            ))}
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <div className="row">
                <Modal show={showDeleteFotoModal} onHide={() => setShowDeleteFotoModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Tem certeza que deseja excluir esta foto? {deleteModalFotoId}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteFotoModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={() => { handleDeleteFoto(deleteModalFotoId); setShowDeleteFotoModal(false); }}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Post" : "Cadastrar Post"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>

                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};

export default PostagemForm;
