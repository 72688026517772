import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { jsPDF } from "jspdf"; // Importa a biblioteca jsPDF

const ListaCurriculos = () => {
  const [curriculos, setCurriculos] = useState([]);

  // Função para buscar todos os currículos
  const fetchCurriculos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos`);
      setCurriculos(response.data);
    } catch (error) {
      console.error('Erro ao buscar os currículos:', error);
    }
  };

  useEffect(() => {
    fetchCurriculos();
  }, []);

  // Função para converter a imagem em base64
  const getBase64FromUrl = async (url) => {
    const response = await axios.get(url, { responseType: 'blob' });
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(response.data);
    });
  };

  // Função para gerar o PDF e abrir em nova aba
  const handleGeneratePdf = async (curriculo) => {
    const doc = new jsPDF();

    // Obter a URL da imagem do candidato
    const imageUrl = `${process.env.REACT_APP_API_IMG}/assets/curriculos/${curriculo.foto}`;
    
    try {
      const imageBase64 = await getBase64FromUrl(imageUrl);
      doc.addImage(imageBase64, 'JPEG', 10, 20, 50, 50); // Posição X, Y, largura, altura
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
    }
    // Adiciona conteúdo ao PDF
    doc.text(`Currículo de ${curriculo.nome}`, 70, 30);
    doc.text(`Email: ${curriculo.email}`, 70, 40);
    doc.text(`Telefone: ${curriculo.telefone}`, 70, 50);
    doc.text(`Experiência: ${curriculo.experiencia || "Não informado"}`, 70, 60);


    // Gera o PDF e abre em uma nova aba
    const pdfData = doc.output('blob');
    const pdfURL = URL.createObjectURL(pdfData);
    window.open(pdfURL, '_blank'); // Abre o PDF em uma nova aba
  };

  return (
    <div className="container mt-4">
      <h2>Lista de Currículos</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {curriculos.map((curriculo) => (
            <tr key={curriculo._id}>
              <td>{curriculo.nome}</td>
              <td>{curriculo.email}</td>
              <td>{curriculo.telefone}</td>
              <td>
                <Button variant="info" onClick={() => handleGeneratePdf(curriculo)}>
                  Gerar PDF
                </Button>{' '}
                {/* <Button variant="danger" onClick={() => handleDelete(curriculo._id)}>
                  Excluir
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ListaCurriculos;
