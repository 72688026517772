import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, ListGroup, Image } from 'react-bootstrap';
import './CurriculoView.css'; // Para estilos adicionais, se necessário

const CurriculoView = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [curriculo, setCurriculo] = useState(null);
  const [loading, setLoading] = useState(true);

  // Busca o currículo no backend
  useEffect(() => {
    const fetchCurriculo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos/${id}`);
        
        setCurriculo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar o currículo:', error);
        setLoading(false);
      }
    };
    fetchCurriculo();
  }, [id]);

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (!curriculo) {
    return <p>Currículo não encontrado.</p>;
  }

  return (
    <Container className="curriculo-view mt-5">
      <Row>
        <Col md={4} className="text-center">
          <Card>
            <Card.Header>
              <h2>{curriculo.nome}</h2>
            </Card.Header>
            <Card.Body>
              {curriculo.foto && (
                <Image
                  src={`${process.env.REACT_APP_API_IMG}/assets/curriculos/${curriculo.foto}`}
                  roundedCircle
                  fluid
                  className="mb-3"
                  alt="Foto do Candidato"
                />
              )}
              <Card.Title>Email</Card.Title>
              <Card.Text>{curriculo.email}</Card.Text>

              <Card.Title>Telefone</Card.Title>
              <Card.Text>{curriculo.telefone}</Card.Text>

              <Card.Title>Endereço</Card.Title>
              <Card.Text>{curriculo.endereco}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Header>
              <h4>Áreas de Interesse</h4>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {curriculo.areasInteresse.ti && <ListGroup.Item>Área Técnica TI</ListGroup.Item>}
                {curriculo.areasInteresse.administrativa && <ListGroup.Item>Área Administrativa</ListGroup.Item>}
                {curriculo.areasInteresse.callCenter && <ListGroup.Item>Call Center</ListGroup.Item>}
                {curriculo.areasInteresse.atendimentoPublico && <ListGroup.Item>Atendimento ao Público</ListGroup.Item>}
                {curriculo.areasInteresse.vendedor && <ListGroup.Item>Vendedor</ListGroup.Item>}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card className="mb-3">
            <Card.Header>
              <h4>Perfil Pessoal</h4>
            </Card.Header>
            <Card.Body>
              <Card.Text>{curriculo.perfil}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <h4>Experiência Profissional</h4>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {curriculo.experiencia.map((exp, index) => (
                  <ListGroup.Item key={index}>
                    {exp}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <h4>Formação Acadêmica</h4>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {curriculo.formacao.map((form, index) => (
                  <ListGroup.Item key={index}>
                    {form}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <h4>Redes Sociais</h4>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {curriculo.linkedin && (
                  <ListGroup.Item>
                    <strong>LinkedIn:</strong> <a href={curriculo.linkedin} target="_blank" rel="noopener noreferrer">{curriculo.linkedin}</a>
                  </ListGroup.Item>
                )}
                {curriculo.github && (
                  <ListGroup.Item>
                    <strong>Github:</strong> <a href={curriculo.github} target="_blank" rel="noopener noreferrer">{curriculo.github}</a>
                  </ListGroup.Item>
                )}
                {curriculo.instagram && (
                  <ListGroup.Item>
                    <strong>Instagram:</strong> <a href={curriculo.instagram} target="_blank" rel="noopener noreferrer">{curriculo.instagram}</a>
                  </ListGroup.Item>
                )}
                {curriculo.facebook && (
                  <ListGroup.Item>
                    <strong>Facebook:</strong> <a href={curriculo.facebook} target="_blank" rel="noopener noreferrer">{curriculo.facebook}</a>
                  </ListGroup.Item>
                )}
                {curriculo.twitter && (
                  <ListGroup.Item>
                    <strong>Twitter:</strong> <a href={curriculo.twitter} target="_blank" rel="noopener noreferrer">{curriculo.twitter}</a>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CurriculoView;