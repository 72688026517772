import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const CardVideoExplicativo = () => {
    const [videos, setVideos] = useState([]);
    const [showModal1, setShowModal1] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingVideo, setEditingVideo] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [video, setVideo] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/videos`);
            setVideos(response.data);
        } catch (error) {
            console.error("Erro ao buscar os videos:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingVideo(null);
        setShowModal1(true);
    };

    const handleCloseModal = () => {
        setShowModal1(false);
        setTitle("");
        setSub("");
        setText("");
        setVideo(null);
        setPreviewVideo(null);
    };

    const handleEditVideo = (video) => {
        setIsEditing(true);
        setEditingVideo(video);
        setTitle(video.title);
        setSub(video.sub);
        setText(video.text);
        setPreviewVideo(`${process.env.REACT_APP_API_IMG}/assets/videos/${video.video}`);
        setShowModal1(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteVideo = async (videoId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/videos/${videoId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchVideos(); // Atualizar a lista de offers

            toast.success('Video deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o video:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalVideoId, setDeleteModalVideoId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("video", video);
        formData.append("text", text);

        try {
            if (isEditing && editingVideo) {
                await axios.put(`${process.env.REACT_APP_API_URL}/videos/${editingVideo._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/videos`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchVideos();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o video:", error);
        }
    };

    const handleVideoChange = (event) => {
        const selectedVideo = event.target.files[0];

        if (selectedVideo) {
            const videoURL = URL.createObjectURL(selectedVideo);
            setPreviewVideo(videoURL);
            setVideo(selectedVideo);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <Card>
                    <Card.Body>
                        <div className="title">
                            <Button onClick={handleOpenModal}>Cadastrar Vídeos</Button>
                        </div>
                        <div className="table-container">
                            <table className="tabela">
                                <thead>
                                    <tr>
                                        <th className="coluna1">Vídeo</th>
                                        <th className="coluna2">Título</th>
                                        <th className="coluna3">Subtítulo</th>
                                        <th className="coluna4">Texto</th>
                                        <th className="coluna5">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {videos.map((video) => (
                                        <tr key={video._id}>
                                            <td>
                                                <div className="video-img">
                                                    <video src={`${process.env.REACT_APP_API_IMG}/assets/videos/${video.midia}`} alt="video" />
                                                </div>
                                            </td>
                                            <td>{video.title}</td>

                                            <td>{video.sub}</td>
                                            <td>
                                                <TextRender htmlContent={video.text} />
                                            </td>
                                            <td>
                                                <div className="botoes">
                                                    <div className="botao">
                                                        <Button variant="outline-success" onClick={() => handleEditVideo(video)}>
                                                            <i class="fa fa-edit" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div className="botao">
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() => {
                                                                setDeleteModalVideoId(video._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>

                                                    {/* <Button variant="primary" onClick={() => handleEditOffer(tv)}>Editar</Button>
                                            <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteModalOfferId(tv._id); }}>
                                                Excluir
                                            </Button> */}

                                                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Tem certeza que deseja excluir este video? {deleteModalVideoId}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                                Cancelar
                                                            </Button>
                                                            <Button variant="danger" onClick={() => { handleDeleteVideo(deleteModalVideoId); setShowDeleteModal(false); }}>
                                                                Excluir
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="row">
                <Modal show={showModal1} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Video" : "Cadastrar Video"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Vídeo</Form.Label>
                                    <FormControl type="file" onChange={handleVideoChange} accept="video/*" />

                                    {/* {previewVideo && <img src={previewVideo} alt="Preview" />} */}
                                    {previewVideo && (
                                        <video width="320" height="240" controls>
                                            <source src={previewVideo} type={video} />
                                            Seu navegador não suporta o elemento de vídeo.
                                        </video>
                                    )}
                                </div>

                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};

export default CardVideoExplicativo;