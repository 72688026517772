import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import CardVideoTv from "../../components/HomeComponents/CardVideoTv";

const TV = () => {
    const [carouselstv, setCarouselstv] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCarouseltv, setEditingCarouseltv] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCarouselstv();
    }, []);

    const fetchCarouselstv = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/carouselstv`);
            setCarouselstv(response.data);
        } catch (error) {
            console.error("Erro ao buscar os carouselstv:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCarouseltv(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditCarouseltv = (carouseltv) => {
        setIsEditing(true);
        setEditingCarouseltv(carouseltv);
        setTitle(carouseltv.title);
        setSub(carouseltv.sub);
        setText(carouseltv.text);
        setPreviewImage(`${process.env.REACT_APP_API_URL}/assets/carouselstv/${carouseltv.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCarouseltv = async (carouselTvId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/carouselstv/${carouselTvId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCarouselstv(); // Atualizar a lista de carrosséis

            toast.success('Carousel deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o carousel:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCarouselId, setDeleteModalCarouselId] = useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingCarouseltv) {
                await axios.put(`${process.env.REACT_APP_API_URL}/carouselstv/${editingCarouseltv._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/carouselstv`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCarouselstv();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o carousel:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="carousel-h3">
                    <h3><i class="fa fa-fw fa-television" aria-hidden="true"></i> Página TV</h3>
                    <span>Atenção para as dimensões das fotos para não distorcer o layout do site! Recomendação:  (1780x665 pixels)</span>
                </div>
                <div className="title">
                    <Button onClick={handleOpenModal}>Cadastrar Carousel</Button>
                </div>
                {carouselstv.map((carouseltv) => (
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div key={carouseltv._id} >
                                <div className="card-title">
                                    <h3>{carouseltv.title}</h3>
                                </div>
                                <p>{carouseltv.sub}</p>
                                <div className="card-img">
                                    <img src={`${process.env.REACT_APP_API_IMG}/assets/carouselstv/${carouseltv.foto}`} alt="Carousel" />
                                </div>
                                <TextRender htmlContent={carouseltv.text} />

                                <div className="carousel-button">
                                    <Button variant="primary" onClick={() => handleEditCarouseltv(carouseltv)} style={{ marginRight: '10px' }}>Editar </Button>
                                    <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteModalCarouselId(carouseltv._id); }}>Excluir</Button>
                                </div>
                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Tem certeza que deseja excluir este carousel?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                            Cancelar
                                        </Button>
                                        <Button variant="danger" onClick={() => { handleDeleteCarouseltv(deleteModalCarouselId); setShowDeleteModal(false); }}>
                                            Excluir
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Carousel" : "Cadastrar Carousel"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
            <div className="row">
                <h3><i className="fa fa-fw fa-television" /> TV</h3>
                <div className="col-sm-12">
                    <br />
                    <CardVideoTv />
                </div>
            </div>

        </div>
    );
};

export default TV;