import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const ProdutoSale = () => {
    const [produtos, setProdutos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingProduto, setEditingProduto] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [price, setPrice] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchProdutos();
    }, []);

    const fetchProdutos = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/produtos`);
            setProdutos(response.data);
        } catch (error) {
            console.error("Erro ao buscar os produtos:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingProduto(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setPrice("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditProduto = (produto) => {
        setIsEditing(true);
        setEditingProduto(produto);
        setTitle(produto.title);
        setSub(produto.sub);
        setPrice(produto.price)
        setText(produto.text);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/produtos/${produto.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteProduto = async (produtoId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/produtos/${produtoId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchProdutos(); // Atualizar a lista de produtos

            toast.success('Produto deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o produto:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalProdutoId, setDeleteModalProdutoId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("price", price);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingProduto) {
                await axios.put(`${process.env.REACT_APP_API_URL}/produtos/${editingProduto._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/produtos`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchProdutos();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o produto:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="title">
                    <Button onClick={handleOpenModal}>Cadastrar Produto</Button>
                </div>

                {produtos.map((produto) => (
                    <div className="col-sm-12 col-md-3" key={produto._id}>
                        <div className="produto">                           
                                <div className="produto-title">                                    
                                    <h3>{produto.title}</h3>
                                </div>
                                <p>{produto.sub}</p>
                                <div className="produto-img">
                                    <img src={`http://localhost:4001/assets/produtos/${produto.foto}`} alt="Produto" />
                                </div>
                                <TextRender htmlContent={produto.text} />

                                <div className="produto-button">
                                    <Button variant="primary" onClick={() => handleEditProduto(produto)}>Editar</Button>
                                    <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteModalProdutoId(produto._id); }}>
                                        Excluir
                                    </Button>

                                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirmar Exclusão</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Tem certeza que deseja excluir este produto? {deleteModalProdutoId}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                Cancelar
                                            </Button>
                                            <Button variant="danger" onClick={() => { handleDeleteProduto(deleteModalProdutoId); setShowDeleteModal(false); }}>
                                                Excluir
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </div>
                           
                        </div>
                    </div>
                ))}
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Produto" : "Cadastrar Produto"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Preço</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};

export default ProdutoSale;