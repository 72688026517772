import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
// import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const CardCategory = ({ categories, setCategories }) => {
    // const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [name, setName] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error("Erro ao buscar os offers:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCategory(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setName("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditCategory = (category) => {
        setIsEditing(true);
        setEditingCategory(category);
        setName(category.name);
        setPreviewImage(`${process.env.REACT_APP_API_URL}/assets/categories/${category.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${categoryId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCategories(); // Atualizar a lista de ofertas
    
            toast.success('Categoria deletada com sucesso!', { position: 'top-right', autoClose: 2000 });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Se a resposta do servidor for 400 (Bad Request), trata-se da situação em que não é possível excluir devido à existência de produtos associados
                toast.error(error.response.data.error, { position: 'top-right', autoClose: 5000 });
            } else {
                console.error("Erro ao deletar a categoria:", error);
            }
        }
    };
    


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCategoryId, setDeleteModalCategoryId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append("name", name);
        formData.append("foto", foto);
    
        try {
            if (isEditing && editingCategory) {
                await axios.put(`${process.env.REACT_APP_API_URL}/categories/${editingCategory._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 });
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/categories`, formData);
                setCategories(prevCategories => [...prevCategories, response.data]); // Adiciona a nova categoria ao estado
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 });
            }
    
            fetchCategories();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar a categoria:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="title">
                        <Button onClick={handleOpenModal}>Cadastrar Categorias</Button>
                    </div>
                    <div className="table-container">
                        <table className="tabela">
                            <thead>
                                <tr>
                                    <th>Imagem</th>
                                    <th>Nome</th>
                                    {/* <th>Descrição</th> */}
                                    <th className="acao">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category) => (
                                    <tr key={category._id}>
                                        <td>
                                            <div className="imagem">
                                                <img
                                                    src={`http://localhost:4001/assets/categories/${category.foto}`}
                                                    alt="Category"
                                                />
                                            </div>
                                        </td>
                                        <td>{category.name}</td>
                                        {/* <td>
                                        <TextRender htmlContent={category.description} />
                                    </td> */}
                                        <td>
                                            <Button variant="warning" onClick={() => handleEditCategory(category)}>
                                                <i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.25em" }}></i>
                                            </Button>
                                            {' '}
                                            <Button
                                                variant="danger"
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setDeleteModalCategoryId(category._id);
                                                }}
                                            >
                                                <i className="fa fa-fw fa-trash" style={{ fontSize: "1.25em" }}></i>
                                            </Button>

                                            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Tem certeza que deseja excluir esta categoria? {deleteModalCategoryId}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            handleDeleteCategory(deleteModalCategoryId);
                                                            setShowDeleteModal(false);
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>{isEditing ? "Editar Category" : "Cadastrar Category"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>

                                <FormGroup>
                                    <Form.Label>Título</Form.Label>
                                    <div className='text-icon'>
                                        <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                        <FormControl type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="foto-preview">
                                        <Form.Label>Foto</Form.Label>
                                        <FormControl type="file" onChange={handleFotoChange} />
                                        {previewImage && <img src={previewImage} alt="Preview" />}
                                    </div>
                                </FormGroup>
                                <br />
                                <Button variant="primary" type="submit">
                                    {isEditing ? "Editar" : "Cadastrar"}
                                </Button>
                                <Button variant="secondary" type="submit">
                                    Fechar
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default CardCategory;