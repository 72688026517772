import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Row, Col, Table } from 'react-bootstrap';
import TV from '../../../assets/images/IconTvNet.png';
import { toast } from 'react-toastify';

import './ComboCard.css';

const ComboCard = () => {
  const [cards, setCards] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [planModalIsOpen, setPlanModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false); // Novo estado para o modal de confirmação de exclusão
  const [editCard, setEditCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null); // Estado para o card selecionado para exclusão ou adicionar plano
  const [newCard, setNewCard] = useState({
    title: '',
    subtitle: '',
    text: ''
  });
  const [newPlan, setNewPlan] = useState({ name: '', description: '' });

  const apiUrl = process.env.REACT_APP_API_URL;

  // Função para buscar os cards do backend
  const fetchCards = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card_combos`);
      setCards(response.data);
    } catch (error) {
      console.error('Erro ao buscar os cards:', error); // Log do erro
    }
  };

  // Função para criar ou editar um card
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editCard) {
      await axios.put(`${apiUrl}/card_combos/${editCard._id}`, newCard);
      toast.success('Plano editado com sucesso!');
    } else {
      await axios.post(`${apiUrl}/card_combos`, newCard);
      toast.success('Card cadastrado com sucesso!');
    }
    setModalIsOpen(false);
    setNewCard({ title: '', subtitle: '', text: '' });
    fetchCards(); // Atualiza a lista
  };

  // Função para abrir o modal de edição com os dados do card
  const handleEdit = (card) => {
    setEditCard(card);
    setNewCard({ title: card.title, subtitle: card.subtitle, text: card.text });
    setModalIsOpen(true);
  };

  // Função para abrir o modal de confirmação de exclusão
  const handleDeleteConfirmation = (card) => {
    setSelectedCard(card);
    setDeleteModalIsOpen(true); // Abre o modal de confirmação de exclusão
  };

  // Função para confirmar e excluir o card
  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/card_combos/${selectedCard._id}`);
      toast.success('Card deletado com sucesso!');
      fetchCards(); // Atualiza a lista
      setDeleteModalIsOpen(false); // Fecha o modal após a exclusão
    } catch (error) {
      toast.error('Erro ao deletar Card!');
      console.error('Erro ao excluir o card:', error); // Log do erro
    }
  };

  // Função para abrir o modal para adicionar planos
  const handleAddPlan = (card) => {
    setSelectedCard(card);
    setPlanModalIsOpen(true);
  };

  // Função para cadastrar novo plano
  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    await axios.post(`${apiUrl}/card_combos/${selectedCard._id}/planos`, newPlan); // Endpoint para adicionar plano
    toast.success('Plano cadastrados com sucesso!');
    setPlanModalIsOpen(false);
    setNewPlan({ name: '', internet: '', internetprice: '', discountedprice: '', tv: '', tvprice: '', price: '', canais: '' });
    fetchCards(); // Atualiza a lista
  };

  // Abre o modal para cadastrar um novo card
  const openModal = () => {
    setEditCard(null);
    setNewCard({ title: '', subtitle: '', text: '' });
    setModalIsOpen(true);
  };

  // Busca os cards ao carregar o componente
  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div className='container'>
      <Button variant="outline-primary" onClick={openModal}>
      <i class="fa fa-television" aria-hidden="true"></i> Criar novo Combo
      </Button>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Título</th>
            <th>Subtítulo</th>
            <th>Texto</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card) => (
            <tr key={card._id}>
              <td>{card.title}</td>
              <td>{card.subtitle}</td>
              <td>{card.text}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(card)}>Editar</Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteConfirmation(card)}>Excluir</Button>{' '}
                <Button variant="info" onClick={() => handleAddPlan(card)}>Adicionar Plano</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal de Criar/Editar Card */}
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title><div className='icon-tv'> <img src={TV} alt='icon-tv' /> {editCard ? 'Editar Card' : 'Novo Combo'} </div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formTitle">
              <Form.Label column sm={2}>Título</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Título"
                  value={newCard.title}
                  onChange={(e) => setNewCard({ ...newCard, title: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formSubtitle">
              <Form.Label column sm={2}>Subtítulo</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Subtítulo"
                  value={newCard.subtitle}
                  onChange={(e) => setNewCard({ ...newCard, subtitle: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formText">
              <Form.Label column sm={2}>Texto</Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  placeholder="Texto"
                  rows={3}
                  value={newCard.text}
                  onChange={(e) => setNewCard({ ...newCard, text: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit">
              {editCard ? 'Atualizar' : 'Criar'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal de Adicionar Plano */}
      <Modal show={planModalIsOpen} onHide={() => setPlanModalIsOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><div className='icon-tv'> <img src={TV} alt='icon-tv' /> Adicionar Plano ao Combo: {selectedCard?.title} </div></Modal.Title>
        </Modal.Header>
        <div className='container'>
          <Modal.Body>
            <Form onSubmit={handleSubmitPlan}>
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanName">
                    <Form.Label column sm={6} className="label">Plano de Internet</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nome do Plano"
                      value={newPlan.planname}
                      onChange={(e) => setNewPlan({ ...newPlan, planname: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanInternet">
                    <Form.Label column sm={6} className="label">Valor do plano de Internet</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Preço da Internet"
                      value={newPlan.internetprice}
                      onChange={(e) => setNewPlan({ ...newPlan, internetprice: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>                
                <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanDiscountedPrice">
                    <Form.Label column sm={6} className="label">Nome do plano de TV</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Plano de TV"
                      value={newPlan.tvplan}
                      onChange={(e) => setNewPlan({ ...newPlan, tvplan: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanTv">
                    <Form.Label column sm={6} className="label">Valor do plano da TV</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Preço do plano de TV"
                      value={newPlan.tvprice}
                      onChange={(e) => setNewPlan({ ...newPlan, tvprice: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanInternetPrice">
                    <Form.Label column sm={6} className="label">Valor do desconto</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Valor do desconto"
                      value={newPlan.discountprice}
                      onChange={(e) => setNewPlan({ ...newPlan, discountprice: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlanTvPrice">
                    <Form.Label column sm={6} className="label">Quantidade de canais</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Quantidade de canais"
                      value={newPlan.numberofchannels}
                      onChange={(e) => setNewPlan({ ...newPlan, numberofchannels: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Adicionar Plano
              </Button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      {/* Modal de Confirmação de Exclusão */}
      <Modal show={deleteModalIsOpen} onHide={() => setDeleteModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir o card "{selectedCard?.title}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalIsOpen(false)}>Cancelar</Button>
          <Button variant="danger" onClick={handleDelete}>Excluir</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ComboCard;
